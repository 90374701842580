import { Box } from "@mui/material";

interface Props {
  score: number;
}

export function SentimentBar({ score }: Props): JSX.Element {
  let color = "";
  let text = "";
  let textColor = "#fff";
  if (score < 1 / 3) {
    color = "#FB5523";
    textColor = "#fff";
    text = "negative";
  }
  if (score > 1 / 3 && score < 2 / 3) {
    color = "#FDD835";
    text = "neutral";
    textColor = "#000";
  }
  if (score > 2 / 3) {
    color = "#6DAE43";
    textColor = "#fff";
    text = "positive";
  }
  console.log("sentiment score:", score);
  console.log("color and text:", color, text);
  return (
    <>
      <Box bgcolor={color} color={textColor} className="px-2" borderRadius={"15px"}>{text}</Box>
    </>
  );
}
