import { Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(4),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
}));
