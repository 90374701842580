import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import * as React from "react";
import Menu from '@mui/material/Menu';
import {adminItems} from "../admin-items";
import { useTranslation } from "react-i18next";



const MenuOption = ({...props}) => {
    const {t} = useTranslation();
    const {anchorElNav, handleCloseNavMenu} = props;
    const pages = adminItems(t);
    return (
        <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={() => handleCloseNavMenu()}
            sx={{
                display: {xs: 'block', md: 'none'},
            }}>
            {pages.map((page) => (
                <div key={page.name}>
                    { !page.link ?<Divider/> : null}
                    <MenuItem onClick={ () => handleCloseNavMenu(page.routerLink)}>
                        <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                </div>
            ))}

        </Menu>
    )
}

export default MenuOption;