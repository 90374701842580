import React, { Component } from "react";
import * as SurveyCreator from "survey-creator-react";
import * as Survey from "survey-core";

import "./defaultV2.css";
import "./survey-creator-core.css";
import "./styles.css"

import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate } from "@azure/msal-react";
import { CircularProgress } from "@mui/material";
import { addNewSurveySlackThunk, addNewQuestionSlackThunk, addNewAnswerSlackThunk, addJSONCardtoSurveySlackThunk, getSingleSurveysWithQandASlackThunk } from "../../../../redux/slack/survey/survey.thunk";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next"
import convertToJsonSlack from "../../../helpers/ConvertToJsonSlack";
import {StylesManager, SurveyCreatorComponent} from "survey-creator-react";
import {Edit} from "@mui/icons-material";
import {spanish} from "../helpers/languajes";
import {localization} from "survey-creator-core";
import {getUserSlackThunk} from "../../../../redux/slack/users/users.thunk";
import {palette} from "../../../../assets/styles/theme";



class CreateSurvey extends Component {

    constructor(props) {
        super(props);

        console.log(this.props.i18n.language)
        localization.currentLocale = localStorage.getItem("i18nextLng");
        if (localStorage.getItem("i18nextLng") === "zh") {
            localization.currentLocale = "zh-tw";
        }
        if (localStorage.getItem("i18nextLng") === "es") {
            spanish();
        }


        const options = {
            questionTypes: ["text", "checkbox", "radiogroup"],
            showLogicTab: false,
            pageEditMode: "single",
            showJSONEditorTab: false,
            haveCommercialLicense: true //Add this line
        };
        this.creator = new SurveyCreator.SurveyCreator(options);
        this.creator.saveSurveyFunc = this.saveMySurvey;



        //!!!!
        //occasionally causes bugs
        //select radio/checkbox, edit item text, go to preview, item text doesn't change and remains item1, item2, etc
        //remove green options in checkboxes and radio questions
        Survey.settings.showDefaultItemsInCreatorV2 = false;

        this.propertyGridSurvey = null;


        this.creator.onSurveyInstanceCreated.add((sender, options) => {
            console.log(options.reason);
            if (options.reason === "property-grid") {
                this.propertyGridSurvey = options.survey;
                console.log("initial panels", this.propertyGridSurvey.getAllPanels())
            }
        });


        this.creator.toolboxLocation = "left"
        this.creator.toolbox.forceCompact = true;

        console.log('items', this.creator.toolbarItems);
        let items = this.creator.toolbarItems;
        //hide undo, redo
        if (this.props.breakpoint !== 'mobile') {
            items[0].visible = false;
            items[1].visible = false;
            items[4].visible = false;
            if (items[10]) items[10].visible = true;
        } else {
            items[0].visible = true;
            items[1].visible = true;
            items[4].visible = true;
            if (items[10])items[10].visible = false;
        }

        //property grid on the right
        this.creator.collapseAllPropertyGridCategories();

        //hide unnecessary options in general section of property editor
        this.creator.onShowingProperty.add(function (_, options) {
            const blackList = ["isRequired", "isLocalizable", "visible", "visibleIf", "readOnly", "category", "maxLength", "minValue", "maxValue", "maxValue", "dependsOn"];
            options.canShow = blackList.indexOf(options.property.name) < 0;

        })

        if (props.myJson) {
            this.creator.JSON = props.myJson.json;
        }


        console.log(Survey.Serializer.getProperties("propertygrid_restfull"))
        //find classes where property is used
        console.log(Survey.Serializer.getAllPropertiesByName("navigateToUrl"));
        //get properties of a class
        console.log(Survey.Serializer.getProperties("survey"))


        //hide unnecessary properties
        Survey.Serializer.getProperty("selectbase", "hasOther").visible = false;
        Survey.Serializer.getProperty("selectbase", "hasNone").visible = false;
        Survey.Serializer.getProperty("selectbase", "hasComment").visible = false;

        Survey.Serializer.getProperty("checkbox", "separateSpecialChoices").visible = false;
        Survey.Serializer.getProperty("checkbox", "hasSelectAll").visible = false;
        Survey.Serializer.getProperty("checkbox", "maxSelectedChoices").visible = false;

        Survey.Serializer.getProperty("radiogroup", "separateSpecialChoices").visible = false;
        Survey.Serializer.getProperty("radiogroup", "showClearButton").visible = false;

        Survey.Serializer.getProperty("question", "description").visible = false;




        //hide logic, data, and other options in property editor
        this.creator.onSelectedElementChanged.add((sender, options) => {
            if (!this.propertyGridSurvey) return;

            console.log("PANELS", this.propertyGridSurvey.getAllPanels())
            this.propertyGridSurvey.getAllPanels().forEach(p => {
                p.visible = (p.name === 'general' || p.name === 'choices');
            });
            this.propertyGridSurvey = undefined;
        });



        //trash icon for deleting question options
        Survey.SvgRegistry.registerIconFromSvg("remove_16x16", '<svg class="svg-icon" viewBox="0 0 20 20"><path d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path></svg>');

        StylesManager.applyTheme('bootstrap');

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let items = this.creator.toolbarItems;
        if (this.props.breakpoint !== 'mobile') {
            items[0].visible = false;
            items[1].visible = false;
            items[4].visible = false;
            if (items[10])items[10].visible = true;
        } else {
            items[0].visible = true;
            items[1].visible = true;
            items[4].visible = true;
            if (items[10])items[10].visible = false;
        }
    }

    static contextType = MsalContext;

    render() {
        console.log(this);

        const customeTheme = {
            "--primary": palette.main,
            "--primary-light": "rgba(28,131,234,0.06)",
            "--secondary": palette.main
        }

        return (

            

            <div style={{ width: '100%', height: '90vh' }}>
                <AuthenticatedTemplate>
                    {this.state.saving ?
                        <div className="text-center">
                            {/* <p>Please wait while your survey gets saved</p> */}
                            <CircularProgress />
                        </div> : <SurveyCreatorComponent creator={this.creator} style={customeTheme} />}
                    <p> {this.state.error} </p>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please Sign To Use the Dashboard</UnauthenticatedTemplate>
            </div>);
    }

    saveMySurvey = async () => {

        console.log(this.creator);

        this.setState({ saving: true });

        try {


            //call this to make sure there is a selected account in state
            // await this.props.getUser(this.context.accounts[0].idTokenClaims.emails[0]);
            if (!this.props?.selectedAccount) {
                await this.props.getUser(this.context.accounts[0].idTokenClaims.emails[0]);
            }
            const account = this.props?.selectedAccount;
            console.log(account);

            let json = this.creator.text;
            console.log(json);
            //parse before saving so it is stored as a json object, not string
            json = JSON.parse(this.creator.text);
            console.log(json);
            await this.props.addNewSurvey({
                title: json.title || " ",
                description: json.description || " ",
                json: json,
                slackUserId: account.id,
                teamId: account.teamId
            })



            for (let q of json.elements) {
                await this.props.addNewQuestion({
                    type: q.type,
                    content: q.title || q.name,
                    name: q.name,
                    slackSurveyId: this.props.singleSurvey.id,
                });
                //add all answers with fk questionid that is created above

                if (q.choices || q.rateValues) {
                    console.log("choices or rate values")
                    let options = q.choices ? q.choices : q.rateValues
                    console.log(options);
                    for (let op of options) {
                        await this.props.addNewAnswer({
                            content: op.text ? op.text : (op.value ? op.value : op),
                            value: op.value ? op.value : op,
                            slackSurveyId: this.props.singleSurvey.id,
                            slackQuestionId: this.props.singleQuestion.id,
                        });
                    }
                }



                /* Matrix Question Not Used in Slack
                 if (q.rows) {

                     for (let op of q.rows) {
                         await this.props.addNewAnswer({
                             content: op.text ? op.text : (op.value ? op.value : op),
                             value: op.value ? op.value : op,
                             nativeSurveyId: this.props.singleSurvey.id,
                             nativeQuestionId: this.props.singleQuestion.id,
                         });
                     }

                 }

                 if (q.columns) {

                     for (let op of q.columns) {
                         await this.props.addNewAnswer({
                             content: op.text ? op.text : (op.value ? op.value : op),
                             value: op.value ? op.value : op,
                             nativeSurveyId: this.props.singleSurvey.id,
                             nativeQuestionId: this.props.singleQuestion.id,
                         });
                     }

                 }
                 */


            }

            await this.props.getSingleSurveysWithQandASlack(this.props.singleSurvey.id);


            let slackCard = convertToJsonSlack(this.props.singleSurvey);
            console.log(slackCard);
            await this.props.addJsonCardSlack(this.props.singleSurvey.id, { card: slackCard })


            this.setState({ saving: false })
            this.setState({ saved: true })

            console.log(this.state, this.props);

            if (this.state.saved) {
                this.props.navigate(`save/${this.props.singleSurvey.id}`)
            }

        } catch (error) {
            console.log("ERROR!!!!!");
            console.log(error);
            this.setState({ error: "" + error });
            this.setState({ saving: false })
        }
    };

    state = {
        //if survey, questions, and answers got added succesfully, display a success
        saved: false,
        //inidicator that there is some progress happening once users click save button
        saving: false,
        error: '',
        loaded: false
    }


}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleSurvey: state.slack.singleSlackSurvey,
        singleQuestion: state.slack.singleSlackQuestion,
        user: state.users.user,
        selectedAccount: state.users.selectedAccount,
        // singleTemplate: state.surveys.singleTemplate

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        addNewSurvey: (obj) => dispatch(addNewSurveySlackThunk(obj)),
        addNewQuestion: (obj) => dispatch(addNewQuestionSlackThunk(obj)),
        addNewAnswer: (obj) => dispatch(addNewAnswerSlackThunk(obj)),
        getUser: (email) => dispatch(getUserSlackThunk(email)),
        addJsonCardSlack: (id, obj) => dispatch(addJSONCardtoSurveySlackThunk(id, obj)),
        getSingleSurveysWithQandASlack: (id) => dispatch(getSingleSurveysWithQandASlackThunk(id)),


        // getTemplate: (id) => dispatch(getSingleTemplateThunk(id)),


    };
};




export default withTranslation()(connect(mapStateToProps
    , mapDispatchToProps
)(CreateSurvey));
