let convertToJsonSlack = (survey)=>{


    let blocks = [
		{
			"type": "section",
			"text": {
				"type": "mrkdwn",
				"text": survey.title || " "
			}
		},
		{
			"type": "section",
			"text": {
				"type": "plain_text",
				"text": survey.description || " ",
				"emoji": true
			}
		},
		{
			"type": "divider"
		}
    ];


   let questions = survey.slack_questions;
   let answers = survey.slack_answers;


   for (let ques of questions){

    let obj = {
        "type": "input",
        "block_id": ques.id.toString(),

    }

    if (ques.type=="text"){

        let element = {
            "type": "plain_text_input",
            "action_id": ques.id.toString()
        };

        let label = {
            "type": "plain_text",
            "text": ques.content || " ",
            "emoji": true
        };
        obj.element= element;
        obj.label = label;
        blocks.push(obj);

    }

    else { 

        let element = {};
        if (ques.type=="radiogroup"){

        element = {
            "type": "radio_buttons"
        };

    }
        if (ques.type=="checkbox"){
        element = {
            "type": "checkboxes"
        }
    }

        let options = [];

        for (let ans of answers){
            if (ans.slackQuestionId == ques.id){
                let option = {
                    "text": {
                        "type": "plain_text",
                        "text": ans.content || " ",
                        "emoji": true
                    },
                    "value": ans.id.toString()
                };

                options.push(option);
            }
        }

        element.options = options;
        element.action_id = ques.id.toString();

        obj.element = element;
        obj.label = {
            "type": "plain_text",
            "text": ques.content || " ",
            "emoji": true
        };
        blocks.push(obj);
    }

 
}


blocks.push({
    "type": "actions",
    "elements": [
        {
            "type": "button",
            "text": {
                "type": "plain_text",
                "text": "Submit",
                "emoji": true
            },
            "value": survey.id.toString(),
            "action_id": "submit_survey"
        }
    ]
});

return blocks;


}

export default convertToJsonSlack;



