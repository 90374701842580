import { Box, Typography } from "@mui/material";
import ReactProgressBar from "@ramonak/react-progress-bar";
import { scoreColor } from "../../utils/scoreColor";

interface Props {
  score: number;
  maxScore: number;
  reversedColor?: boolean;
}

export function ProgressBar({
  score,
  maxScore,
  reversedColor = false,
}: Props): JSX.Element {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <ReactProgressBar
          completed={score}
          maxCompleted={maxScore}
          isLabelVisible={false}
          bgColor={
            !reversedColor
              ? scoreColor(score, maxScore)
              : scoreColor(maxScore - score, maxScore)
          }
        />
        <Typography
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "center",
            fontSize: 13,
          }}
          color={!score ? "#fff" : "#000"}
        >
          {score.toFixed(2) ? score.toFixed(2)?.toString() : '0'}
        </Typography>
      </Box>
    </>
  );
}
