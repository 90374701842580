import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { Question, Type1Question, Type2Question } from "../../models/Question";
import { Type1QuestionChart } from "./Type1QuestionChart";
import { Type2QuestionChart } from "./Type2QuestionChart";

interface Props {
  questions: Question[];
  type: "type1" | "type2";
  startIndex?: number;
}

export function ChartsBlock({
  questions,
  type,
  startIndex = 1,
}: Props): JSX.Element {
  if (type === "type1") {
    return (
      <Box>
        {questions.map((question, index) => (
          <Box key={question.id}>
            {
            <Type1QuestionChart
              question={question as Type1Question}
              index={startIndex + index}
            />
          }
          </Box>
        ))}
      </Box>
    );
  }

  if (type === "type2") {
    return (
      <Box>
        {questions.map((question, index) => (
          <Box key={question.id}>
            {
              <Type2QuestionChart
                question={question as Type2Question}
                index={startIndex + index}
              />
            }
          </Box>
        ))}
      </Box>
    );
  }

  return <></>;
}
