import React, {Component, memo} from "react";
import {connect} from "react-redux";

import {MsalContext} from "@azure/msal-react";
import axios from "axios";
import {Divider, FormControl, IconButton, InputLabel, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {ContentCopy} from "@mui/icons-material";
import Select from "@mui/material/Select";
import {getUserSlackThunk} from "../../../redux/slack/users/users.thunk";

const CustomerPortal = memo(class extends Component {

    static contextType = MsalContext;

    async componentDidMount() {
        if(!this.props.user) await this.props.getUserSlack(this.context.accounts[0].idTokenClaims.emails[0]);
    }


    stripePortal = async () => {
        let obj = {
            stripeCustomerId: this.props.selectedUser.stripeCustomerId
        }

        //   const requestOptions = {
        //     method: 'POST',
        //     // mode: 'no-cors',
        //     headers: { 'Content-Type': 'application/json',
        //                 'Access-Control-Allow-Origin': '*',
        //                 'Accept': '*/*' },
        //     redirect: 'follow',
        //     body: JSON.stringify({ stripeCustomerId: this.props.user[0].stripeCustomerId })
        // };
        // fetch('https://survey-app-backend.azurewebsites.net/api/stripe/create-customer-portal-session', requestOptions)
        //   //  .then(response => console.log(response));

        let res = await axios.post("https://survey-app-backend.azurewebsites.net/api/stripe/create-customer-portal-session", obj);

        // fetch(res.data);

        // window.open("https://billing.stripe.com/session/test_YWNjdF8xSm1rNVpENjJmRUdXZ2xCLF9LVzlEOFdXbXVzczFRYkY1bjcxZEZrYUVTamthV2xW0100Czwnxf92", "_parent", false);


        // window.location.assign('https://developer.mozilla.org/en-US/docs/Web/API/Location/reload');
        window.location.href = res.data;
    }

    purchaseSubscription = async (e) => {
        console.log(e.currentTarget.value);

        let obj = {
            email: this.context.accounts[0].idTokenClaims.emails[0],
            type: e.currentTarget.value
        };
        let res = await axios.post("https://survey-app-backend.azurewebsites.net/api/stripe/create-checkout-session", obj);

        window.location.href = res.data;
    }

    render() {
        console.log('RENDER CUSTOMER PORTAL', this.props)
        const {t} = this.props;
        return (
            this.props.user.length > 0 ?
                <span>
                <MenuItem key="user">
                        <div>{this.props.user[0].firstName + ' ' + this.props.user[0].lastName}
                            <Typography variant="body2" className="text-black-50">
                                <small>
                                    {'Team ID: ' + this.props.selectedTeam}
                                    {/*'Team ID: ' + this.context.accounts[0].idTokenClaims.extension_teamId*/}
                                    {/*<IconButton onClick={() => console.log('click aqui')}><ContentCopy/></IconButton>*/}
                                </small>
                            </Typography>

                                <FormControl sx={{mr: 2, display:{ xs: 'inline-block', md: 'none'}}} size="small" className="text-black-50 mt-4 select-nav" dense="true">
                                    <InputLabel id="team">{t('team.name')}</InputLabel>
                                    <Select name="team"
                                            labelId="team"
                                            id="team"
                                            value={this.props.team}
                                            label="Select Your Team"
                                            onChange={(e) => this.props.handleTeamChange(e)}>
                                        {this.props.user.map((us, index) =>
                                            <MenuItem value={us.teamId} key={index}>{us.teamName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                        </div>

                </MenuItem>
                <Divider variant="middle" hidden={!this.props.user[0].admin}/>
                    {this.props.user[0].ownedSubscriptions.length < 1 ?
                        null
                        :
                        this.props.user[0].admin ?
                        <MenuItem onClick={() => {
                            this.stripePortal();
                            this.props.handleCloseUserMenu('')
                        }} key="manager">
                            <Typography textAlign="center">{t('billing.manage')}</Typography>
                        </MenuItem> : null}
                    {this.props.user[0].admin ?
                        <MenuItem onClick={() => {this.props.handleCloseUserMenu('')}} key="assign">
                        <Link to={{pathname: "/assignSubscriptions", state: {email: this.props.email}}}
                          className="text-decoration-none">
                            <Typography className="text-black">{t('billing.assign')}</Typography>
                        </Link>
                    </MenuItem> : null}
            </span> : null
        );
    }
});

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state customer portal", state);
    return {
        user: state.users.user,
        selectedTeam: state.users.selectedTeam,
        selectedUser: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSlack: (email) => dispatch(getUserSlackThunk(email))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPortal);
