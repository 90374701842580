import React, {Component} from "react";
import "survey-react/survey.css";
import * as Survey from "survey-react";

import {connect} from "react-redux";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import {Button, CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";
import {getSingleSurveysWithQandASlackThunk} from "../redux/slack/survey/survey.thunk";


class DisplaySlackSurvey extends Component {
    state = {
        json: undefined,
        loaded: false,
    };

    async componentDidMount() {
        ///let id = this.props.match.params.id;
        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]

        await this.props.getSingleSlackSurvey(id);
        this.setState({json: this.props.singleSlackSurvey.json, loaded: true});
    }

    componentDidUpdate() {

    }

    render() {
        return (
            <div>

                <AuthenticatedTemplate>
                    <div className="py-3 text-end">
                        <Link to={`/surveys-slack`} style={{textDecoration: 'none'}}>
                            <Button variant="contained">Back</Button>
                        </Link>
                    </div>
                    {!this.state.loaded && this.state.json === undefined ? (
                        <div className="text-center">
                            <CircularProgress/>
                        </div>
                    ) : (
                        <div>
                            <Survey.Survey
                                json={this.state.json}
                                showNavigationButtons={false}/>
                        </div>
                    )}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please Sign In to View This Page</UnauthenticatedTemplate>


            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        singleSlackSurvey: state.slack.singleSlackSurvey,
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getSingleSlackSurvey: (id) => dispatch(getSingleSurveysWithQandASlackThunk(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySlackSurvey);
