import axios from "axios";
import {
    addJSONCardtoSurveySlackThunk, addJSONtoSurveySlackThunk, addScheduleSlackThunk, deleteScheduleSlackThunk,
    getAllSurveysSlackThunk, getAllSurveysWithScheduleSlackThunk,
    getSingleSurveysWithQandASlackThunk
} from "../redux/slack/survey/survey.actions";

export const addNewSurveySlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newSurvey`, obj);
    return data;
}

export const addNewQuestionSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/questions/newQuestion`, obj);
    return data;
}

export const addNewAnswerSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/answers/newAnswer`, obj);
    return data;
}

export const getSingleSurveysWithQandASlackService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/singleSurveyFull/${id}`);
    return data;
}

export const addJSONCardtoSurveySlackService = async (id, obj) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newSurvey/json/${id}`, obj);
    return data;
}

export const getAllSurveysSlackService = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/allSurveys`);
    return data;
}


export const addJSONtoSurveySlackService = async (id, obj) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/newSurvey/${id}`, obj);
    return data;
}

export const addScheduleSlackService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/schedules/newSchedule`, obj);
    return data;
}

export const getAllSurveysWithScheduleSlackService = async (teamId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/surveys/allSurveysWithSchedule/${teamId}`);
    return data;
}

export const deleteScheduleSlackService = async (id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/slack/schedules/editSchedule/${id}`, {
        state: false
    });
    return data;

}
