import SlackUserActionTypes from "./users.types";

const INITIAL_STATE = {
  status: 'initial',
  user: {},
  lastUser: {},
  selectedTeam: localStorage.slackTeamId || '',
  selectedAccount: {}
};

const usersReducer = (state = INITIAL_STATE, action) => {
  console.log("PL", action);
  switch (action.type) {
    case SlackUserActionTypes.CHANGE_STATUS_USER:
      return { ...state, status: action.payload };
    case SlackUserActionTypes.GET_USER_SLACK:
      return { ...state, user: action.payload };
    case SlackUserActionTypes.GET_USER_SLACK_LAST_TIME:
      return { ...state, lastUser: action.payload };
    case SlackUserActionTypes.CHANGE_TEAM:
      return {...state, selectedTeam: action.payload}
    case SlackUserActionTypes.CHANGE_ACCOUNT:
      return {...state, selectedAccount: action.payload}
  
    default:
      return state;
  }
};

export default usersReducer;
