import { combineReducers } from "redux";

//import here


import slackSurveysReducer from "./slack/survey/survey.reducer";
import usersReducer from "./slack/users/users.reducer";

const rootReducer = combineReducers({

  slack: slackSurveysReducer,
  users: usersReducer
});

export default rootReducer;
