import { useContext } from "react";
import { Link } from "react-router-dom";
import { ChartsContext } from "../components/ChartsContext";
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Unstable_Grid2 as Grid,
  Box,
  Typography,
} from "@mui/material";
import {
  DimensionCard,
  ProgressBar,
  ReturnBar,
  SentimentBar,
} from "../components/ui";

export default function Overview(): JSX.Element {
  const chartsContext = useContext(ChartsContext);

  const { isLoading, errorMessage, survey, questions } = chartsContext;

  let surveyScore = -1;
  if (survey.dimensions.length > 0) {
    surveyScore =
      survey.dimensions
        .map((dimension) => dimension.score)
        .reduce((previousValue, currentValue) => previousValue + currentValue) /
      survey.dimensions.length;
  }

  /*
  let surveyParticipation = -1;
  console.log(questions)
   if (
    questions !== undefined && 
    questions.length > 0 &&
    (questions[0] as Type1Question).choiceValues?.length > 0
  ) {
    surveyParticipation = (questions[0] as Type1Question).choiceValues.reduce(
      (previousValue, currentValue) => previousValue + currentValue
    );
  }
  */

  let surveyMaxScore = -1;
  if (survey.dimensions.length > 0) {
    surveyMaxScore =
      survey.dimensions
        .map((dimension) => dimension.maxScore)
        .reduce((previousValue, currentValue) => previousValue + currentValue) /
      survey.dimensions.length;
  }

  return (
    <>
      <Box padding={"20px"} bgcolor={"#F9F9F9"}>
        <ReturnBar title={survey.title} />

        {errorMessage}
        {isLoading ? "Loading" : ""}

        {!isLoading && (
          <Box>
            <Grid
              container
              spacing={3}
              sx={{
                marginY: 2,
              }}
            >
              <Grid xs={3}>
                <DimensionCard
                  title={survey.templateName + " Score"}
                  score={surveyScore}
                  maxScore={surveyMaxScore}
                />
              </Grid>
              {survey.dimensions.map((dimension) => {
                if (dimension.name !== "") {
                  return (
                    <Grid key={dimension.name} xs={3}>
                      {survey.templateName !== "Employee Burnout" ? (
                        <DimensionCard
                          title={dimension.name}
                          score={dimension.score}
                          maxScore={dimension.maxScore}
                        />
                      ) : (
                        <DimensionCard
                          title={dimension.name}
                          score={dimension.score}
                          maxScore={dimension.maxScore}
                          reversedColor={true}
                        />
                      )}
                    </Grid>
                  );
                }
                return "";
              })}
              <Grid xs={3}>
                <DimensionCard
                  title={"Participation"}
                  score={survey.numberOfResponses}
                  round
                />
              </Grid>
            </Grid>

            <Box
              alignItems={"center"}
              boxShadow={"2"}
              borderRadius={"10px"}
              padding={"10px"}
            >
              <Grid
                container
                spacing={1}
                justifyContent={"space-between"}
                sx={{
                  marginY: 2,
                }}
              >
                <Box>
                  <Typography
                    fontSize={"20px"}
                    fontFamily={"IBM Plex Sans"}
                    fontWeight={"SemiBold"}
                    color={"#1A237E"}
                  >
                    Questions
                  </Typography>
                  <Link to={`/charts/${survey.id}/questions-data`}>
                    View all questions data
                  </Link>
                </Box>
                {/*<Link to={`/charts/${survey.id}/comparative-information`}>
                  Dimensions
              </Link>*/}
              </Grid>
              <TableContainer
                sx={{
                  borderRadius: 1,
                  boxShadow: 0,
                  margin: 1,
                  width: "calc(100% - 16px)",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                      <TableCell
                        sx={{
                          width: "80%",
                          color: "#1A237E",
                          fontSize: "16px",
                          fontFamily: "IBM Plex Sans",
                          fontWeight: "SemiBold",
                        }}
                      >
                        Question
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "10%",
                          color: "#1A237E",
                          fontSize: "16px",
                          fontFamily: "IBM Plex Sans",
                          fontWeight: "SemiBold",
                        }}
                      >
                        Results
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "10%",
                          color: "#1A237E",
                          fontSize: "16px",
                          fontFamily: "IBM Plex Sans",
                          fontWeight: "SemiBold",
                        }}
                      >
                        Dimension
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questions.map((question, index) => (
                      <TableRow key={question.id}>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontFamily: "IBM Plex Sans",
                            fontWeight: "Medium",
                          }}
                        >
                          {index + 1}. {question.title}
                        </TableCell>
                        <TableCell>
                          {question.type === "checkbox" ||
                          question.type === "dropdown" ||
                          question.type === "emotionsratings" ||
                          question.type === "imagepicker" ||
                          question.type === "matrix" ||
                          question.type === "radiogroup" ||
                          question.type === "rating" ? (
                            survey.templateName !== "Employee Burnout" ? (
                              <ProgressBar
                                score={question.score}
                                maxScore={question.maxScore}
                              />
                            ) : (
                              <ProgressBar
                                score={question.score}
                                maxScore={question.maxScore}
                                reversedColor
                              />
                            )
                          ) : (
                            question.type === "comment" ||
                            (question.type === "text" && (
                              <SentimentBar score={question.score} />
                            ))
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "16px",
                            fontFamily: "IBM Plex Sans",
                            fontWeight: "Regular",
                          }}
                        >
                          {question.dimension}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
