import { Typography, Box } from "@mui/material";
import { scoreColor } from "../../utils/scoreColor";

interface Props {
  title: string;
  score: number;
  maxScore?: number;
  round?: boolean;
  reversedColor?: boolean;
}

export function DimensionCard({
  title,
  score,
  maxScore,
  round = false,
  reversedColor = false,
}: Props): JSX.Element {
  console.log("MAX", maxScore)
  return (
    <>
      <Box
        alignItems={"center"}
        boxShadow={"3"}
        borderRadius={"10px"}
        padding={"20px"}
      >
        <Typography
          fontSize={"18px"}
          fontFamily={"IBM Plex Sans"}
          fontWeight={"SemiBold"}
          color={"#1A237E"}
        >
          {title}
        </Typography>
        <Typography
          fontSize={"32px"}
          fontFamily={"IBM Plex Sans"}
          fontWeight={"SemiBold"}
          color={
            maxScore !== undefined
              ? !reversedColor
                ? scoreColor(score, maxScore)
                : scoreColor(maxScore - score, maxScore)
              : "#1A237E"
          }
        >
          {!score ? 0 : !round ? score.toFixed(2) : score.toFixed(0)}
        </Typography>
      </Box>
    </>
  );
}
