import React, {Component} from "react";
import {connect} from "react-redux";
import {MsalContext} from "@azure/msal-react";
import {Alert, Box} from "@mui/material";
import SurveySlackCard from "./survey-slack-card/survey-card";
import axios from "axios";
import querystring from "querystring";
import {
    getAllSurveysWithScheduleSlackThunk,
    getSingleSurveysWithQandASlackThunk
} from "../../../redux/slack/survey/survey.thunk";

class SurveySlackIndex extends Component {

    static contextType = MsalContext;

    state = {
        sending: [],
        onOpen: false,
        status: '',
        msg: ''
    }

    componentDidMount() {
        // const teamId =this.context.accounts[0].idTokenClaims.extension_teamId;
        this.props.getAllSurveysWithScheduleSlack(this.props.selectedTeam);

    }

    componentDidUpdate(prevProps){
        if(this.props.selectedTeam !== prevProps.selectedTeam){
            this.props.getAllSurveysWithScheduleSlack(this.props.selectedTeam);
        }

    }

    sendSurveyToTeam = async (id, title, teamId) => {

        let newSending = [...this.state.sending];
        newSending[id] = true;
        this.setState({sending: newSending});
        console.log(this.state);

        await this.props.getSingleSurveySlack(id);
        console.log(this.props.singleSlackSurvey.jsonBlocks);


        let res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/users/all/${teamId}`);
        console.log(res);
        let token = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/installations/getOne/${teamId}`);
        console.log(token);


        token = token.data[0].bot_token;


        let users = res.data;

        let count = 0;

        for (let us of users) {


            let url = `https://slack.com/api/chat.postMessage`
            let obj = {
                token: token,
                channel: us.id,
                text: "New Survey",
                blocks: JSON.stringify(this.props.singleSlackSurvey.jsonBlocks)


            }
            try {
                //querystring because body needs to be in form-data format
                let response = await axios.post(url, querystring.stringify(obj));
                console.log(response);
                if (!response.data.error) {
                    count++;

                }


            } catch (error) {
                console.log(error);

            }


        }

        if (count > 0) {
            this.setState({
                onOpen: true,
                status: 'success',
                msg: "Succesfully sent " + title + " to " + count + " people"
            })

        } else {
            this.setState({
                onOpen: true,
                status: 'error',
                msg: "An error occured when sending, please try again "
            })
        }
        newSending = [...this.state.sending];
        newSending[id] = false;
        this.setState({sending: newSending});

    }

    getActiveSchedule = (schedules) => {

        if (schedules.length > 0) {
            for (let schedule of schedules) {
                if (schedule.state === true) {
                    console.log(schedule);
                    return schedule;
                }
            }
        }
        return false;
    }

    render() {
        return (
            <div>
                {/*<Box sx={{display: {xs: 'block', md: 'none'}}}>
                    <SurveyCard surveys={this.props.allSlackSurveys} status={this.props.status}/>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    <SurveyTable surveys={this.props.allSlackSurveys} status={this.props.status}/>
                </Box>*/}
                {this.state.onOpen ?
                    <Alert severity={this.state.status} className="alert-style my-3" onClose={() => this.setState({onOpen: false})}>{this.state.msg}</Alert>:null
                }
                <Box>
                    <SurveySlackCard surveys={this.props.allSlackSurveys} status={this.props.status} sendSurveyToTeam={this.sendSurveyToTeam} sending={this.state.sending}/>
                </Box>
            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSlackSurveys: state.slack.allSlackSurveys,
        singleSlackSurvey: state.slack.singleSlackSurvey,
        status: state.slack.status,
        selectedTeam: state.users.selectedTeam
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        getSingleSurveySlack: (id) => dispatch(getSingleSurveysWithQandASlackThunk(id))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SurveySlackIndex);