//this component is used to overcome the problem with pages in templates
//gets the template and renders the creator component passing the template json

import React, { Component } from "react";
import * as SurveyCreator from "survey-creator-react";
import * as Survey from "survey-core";

import { connect } from "react-redux";
import { MsalContext, UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";

import CreateSurvey from "./start-from-scratch-index";
import {CircularProgress} from "@mui/material";


class MySurveyCreator extends Component {

    state = {
        // load the template
        loaded: false,
        json: {}

    }

    constructor(props) {
        super(props);
    }




    componentDidMount() {

        // const path = window.location.pathname.split('/')
        // console.log(path);
        // const id = path[path.length - 1];
        // console.log(id);
        // if (id) {
        //     this.props.getTemplate(id).then(() => {
        //         this.setState({ json: this.props.singleTemplate });
        //         this.setState({ loaded: true })
        //     }
        //     )
        // }

        this.setState({json: {}});
        this.setState({loaded: true});

    }


    static contextType = MsalContext;

    render() {
        console.log(this);
        return (

            <div>
                <AuthenticatedTemplate>
                   {this.state.loaded ? 
                  <CreateSurvey myJson={this.state.json} navigate={this.props.navigate} breakpoint={this.props.breakpoint}/>
                   :
                       <div className="text-center">
                           <p>Loading...</p>
                           <CircularProgress/>
                       </div>}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please Sign To Use the Dashboard</UnauthenticatedTemplate>
            </div>);
    }


  



}



// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        
        // singleTemplate: state.surveys.singleTemplate

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        
        // getTemplate: (id) => dispatch(getSingleTemplateThunk(id)),
       



    };
};

export default connect(mapStateToProps
    , mapDispatchToProps
)(MySurveyCreator);
