import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom"

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";


import {msalConfig} from "./auth/authConfig"
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import {CircularProgress, ThemeProvider} from "@mui/material";
import {theme} from "./assets/styles/theme";
import './i18n';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Suspense fallback={
              <div className="text-center col-12">
                <p>Loading...</p>
                <CircularProgress/>
              </div>
            }>
            <App instance = {msalInstance}/>
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
      </MsalProvider>

  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
///hello
