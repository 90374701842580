import {
    changeSelectedAccount,
    changeSelectedTeam,
    changeStatusUser,
    getUserSlack,
    getUserSlackLastTime
} from "./users.actions";
import {getUserSlackLastTimeService, getUserSlackService} from "../../../services/user.service";

export const getUserSlackThunk = (email) => {
    return async (dispatch) => {
        dispatch(changeStatusUser('loading'));
        try {
            const data = await getUserSlackService(email);
            console.log("data", data);
            if (!data.error) {
                dispatch(getUserSlack(data));
                if (!localStorage.slackTeamId) {
                    localStorage.setItem("slackTeamId", data[0].teamId);
                    //set to first team id by default
                    dispatch(changeSelectedTeam(data[0].teamId));
                    //set to fisrt account by default
                    dispatch(changeSelectedAccount(data[0]))
                }
                //if there is team id in local storage, set selectedAcount based on that
                else {
                    const selectedAccount = getSelectedAccount(data, localStorage.slackTeamId);
                    dispatch(changeSelectedAccount(selectedAccount));
                }
                dispatch(changeStatusUser('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const getUserSlackLastTimeThunk = () => {
    return async (dispatch) => {
        dispatch(changeStatusUser('loading'));
        try {
            const data = await getUserSlackLastTimeService();
            console.log("data Last", data);
            if (!data.error) {
                dispatch(getUserSlackLastTime(data));
                dispatch(changeStatusUser('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    }
}


export const changeSelectedTeamThunk = (teamId, accounts) => {
    return async (dispatch) => {
        localStorage.setItem("slackTeamId", teamId);
        dispatch(changeSelectedTeam(teamId));
        const selectedAccount = getSelectedAccount(accounts, teamId);
        dispatch(changeSelectedAccount(selectedAccount));
    }
}


function getSelectedAccount(accounts, teamId) {
    for (let acc of accounts) {
        if (acc.teamId === teamId) {
            return acc;
        }
    }

}


