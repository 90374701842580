import {
    addJSONCardtoSurveySlackService,
    addJSONtoSurveySlackService,
    addNewAnswerSlackService,
    addNewQuestionSlackService,
    addNewSurveySlackService,
    addScheduleSlackService, deleteScheduleSlackService,
    getAllSurveysSlackService,
    getAllSurveysWithScheduleSlackService,
    getSingleSurveysWithQandASlackService
} from "../../../services/survey.service";
import {
    addJSONCardtoSurveySlack,
    addJSONtoSurveySlack,
    addNewAnswerSlack,
    addNewQuestionSlack,
    addNewSurveySlack,
    addScheduleSlack,
    changeStatusSlack,
    deleteScheduleSlack,
    getAllSurveysSlack,
    getAllSurveysWithScheduleSlack,
    getSingleSurveyWithQandASlack
} from "./survey.actions";

export const addNewSurveySlackThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeStatusSlack('loading'));
        try {
            const data = await addNewSurveySlackService(obj);
            console.log("data ADD SURVEY", data);
            if (!data.error) {
                dispatch(addNewSurveySlack(data));
                dispatch(changeStatusSlack('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const addNewQuestionSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewQuestionSlackService(obj);
            console.log("data ADD QUESTION", data);
            if (!data.error) dispatch(addNewQuestionSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};


export const addNewAnswerSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addNewAnswerSlackService(obj);
            console.log("data ADD ANS", data);
            if (!data.error) dispatch(addNewAnswerSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getSingleSurveysWithQandASlackThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await getSingleSurveysWithQandASlackService(id);
            console.log("data GET SUR Q A", data);
            if (!data.error) dispatch(getSingleSurveyWithQandASlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};


//adds json for slack survey card
export const addJSONCardtoSurveySlackThunk = (id, obj) => {
    return async (dispatch) => {
        try {
            const data = await addJSONCardtoSurveySlackService(id, obj);
            console.log("data", data);
            if (!data.error) dispatch(addJSONCardtoSurveySlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllSurveysSlackThunk = () => {
    return async (dispatch) => {
        try {
            const data = await getAllSurveysSlackService();
            console.log("data", data);
            if (!data.error) dispatch(getAllSurveysSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addJSONtoSurveySlackThunk = (id, obj) => {
    return async (dispatch) => {
        try {
            const data = await addJSONtoSurveySlackService(id, obj);
            console.log("data", data);
            if (!data.error) dispatch(addJSONtoSurveySlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const addScheduleSlackThunk = (obj) => {
    return async (dispatch) => {
        try {
            const data = await addScheduleSlackService(obj);
            console.log("data", data);
            if (!data.error) dispatch(addScheduleSlack(data));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAllSurveysWithScheduleSlackThunk = (teamId) => {
    return async (dispatch) => {
        dispatch(changeStatusSlack('loading'));
        try {
            const data = await getAllSurveysWithScheduleSlackService(teamId);
            console.log("data", data);
            if (!data.error) {
                dispatch(getAllSurveysWithScheduleSlack(data));
                dispatch(changeStatusSlack('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

//not deleting, but updating
export const deleteScheduleSlackThunk = (id) => {
    return async (dispatch) => {
        try {
            const data = await deleteScheduleSlackService(id);
            console.log("data", data);
            if (!data.error) dispatch(deleteScheduleSlack());
        } catch (error) {
            console.error(error);
        }
    };
};













