import { Outlet } from "react-router-dom";
import { ScopedCssBaseline } from "@mui/material";
import { ChartsProvider } from "./ChartsContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chart.js/auto"; // fix: Canvas is already in use. Chart with ID '0' must be destroyed before the canvas can be reused.
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Register the plugin to all charts:
ChartJS.register(ChartDataLabels);

export function ChartsMain(): JSX.Element {
  return (
    <>
      <ScopedCssBaseline>
        <ChartsProvider>
          <Outlet />
        </ChartsProvider>
      </ScopedCssBaseline>
    </>
  );
}
