import { useState } from "react";
import { Survey } from "../../models/Survey";
import { Question } from "../../models/Question";
import { Line } from "react-chartjs-2";

interface Props {
  survey: Survey;
  //questions: IQuestion[],
  questions: any[];
  currentSurvey: Question[];
  titleSurveyCurrent: string;
  dimension: string;
}
/***
 *
 */
export function ComparativeInformationChart(props: Props): JSX.Element {
  const [filter, setFilter] = useState("month");

  const changeFilter = (event: any): void => {
    setFilter(event.target.value);
  };
  console.log("Antes: ComparativeInformationChart: ", props);
  /*
  const chartConfig = new DimensionsChartConfig(
    props.questions,
    props.currentSurvey,
    props.titleSurveyCurrent,
    props.dimension,
    filter
  );
  */
  console.log("despues: ComparativeInformationChart: ", props);

  // const { options, data } = chartConfig.createChartConfig();
  const value = 5.98;
  const textValue = "Job Security";
  // console.log("this is ComparativeInformation: options", options);
  // console.log("this is ComparativeInformation: data", data);
  const color =
    value > 0.66 ? (value > 0.33 ? "#6DAE43" : "#FDD835") : "#FB5523";

  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className="flex flex-col justify-start h-60 mt-1 mb-1 bg-white mitad">
        <select className="w-60 ml-auto" name="" id="" onClick={changeFilter}>
          <option value="month">Monthly</option>
          <option value="year">Year</option>
          <option value="week">Weekly</option>
        </select>
        <div className="flex-grow h-40 mitad">
          <Line options={options} data={data} />
        </div>
      </div>
    </>
  );
}
