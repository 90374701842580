import {localization} from "survey-creator-core";

export const spanish = () => {
    const translations = localization.getLocale("es");

    ///LICENCE
    translations.survey.license = 'Debe comprar una licencia de desarrollador para usar Survey Creator en su aplicación';

    /// Design
    translations.ed.surveyPlaceHolder = 'La encuesta está vacía. Arrastre un elemento de la caja de herramientas o haga clic en el botón de abajo.';
    translations.ed.addNewQuestion = 'Añadir pregunta';

    /// Survey Logic
    translations.ed.lg.showAllQuestions = 'Todas las preguntas';
    translations.ed.lg.showAllActionTypes = 'Con preguntas contestadas';

    translations.survey.addLogicItem = 'Cree una regla par  a personalizar el flujo de la encuesta';

    translations.ed.lg.addNewItem = 'Añadir regla';

}