import { Context } from "chartjs-plugin-datalabels";
import { floatingLabels } from "../../utils/chartjsPlugins";
import { Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Bar, Doughnut } from "react-chartjs-2";
import { Type1Question, Question } from "../../models/Question";
import { scoreInterpolationColor } from "../../utils/scoreColor";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  width: "100%",
  height: "",
}));

interface Props {
  index: number;
  question: Type1Question;
}

export function Type1QuestionChart({ index, question }: Props): JSX.Element {
  // let chartConfig = new RatingChartConfig(question);
  // let { options, data } = chartConfig.createChartConfig();
  // options.plugins = { legend: { display: false } };
  // // options.maintainAspectRatio = false;

  const sortedChoices = question.choices
    .reduce(
      (
        sortedChoices: [string, number][],
        currentValue: string,
        currentIndex: number
      ) => {
        sortedChoices.push([currentValue, question.choiceValues[currentIndex]]);
        return sortedChoices;
      },
      [] as [string, number][]
    )
    .sort(([, a], [, b]) => b - a);

  /* Example:
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        borderWidth: 1,
      },
    ],
  };
  
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  */

  const colors = question.choices.map((value, index) =>
    scoreInterpolationColor(index, question.maxScore)
  );

  const data = {
    labels: sortedChoices.map((value) => value[0]), // question.choices,
    datasets: [
      {
        label: "label",
        data: sortedChoices.map((value) => value[1]), // question.choiceValues,
        borderWidth: 1,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    /*scales: {
      y: {
        beginAtZero: true,
      },
    },*/
    //indexAxis: "y" as const,

    plugins: {
      datalabels: {
        color: "#111",
        textAlign: "center",
        font: {
          lineHeight: 1.6,
        },
        formatter: (value: number, ctx: Context) => {
          if (ctx.chart.data.labels && value > 0) {
            return (
              (
                (value /
                  question.choiceValues.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue
                  )) *
                100
              ).toFixed(2) + "%"
            );
          } else if (value === 0) {
            return "";
          }
        },
      },
    },
  };

  // console.log("choices", question.choices);
  // console.log("choiceValues", question.choiceValues);
  // console.log("colors", colors);

  return (
    <Item style={{ width: "60%" }}>
      <h3
        style={{
          fontSize: "18px",
          fontWeight: "medium",
          color: "#1A237E",
          fontFamily: "'IBM Plex Sans', sans-serif",
        }}
      >
        {index}. {question.title}
      </h3>
      <div>
        <Doughnut
          // @ts-ignore
          options={options}
          data={data}
          // plugins={[floatingLabels]}
        />
      </div>
    </Item>
  );
}
