import SurveyActionTypes from "./survey.types";

//ACTION CREATORS

export const changeStatusSlack = (payload) => ({
    type: SurveyActionTypes.CHANGE_STATUS_SLACK,
    payload,
});

export const addNewSurveySlack = (payload) => ({
    type: SurveyActionTypes.ADD_NEW_SURVEY_SLACK,
    payload,
});

export const addNewQuestionSlack = (payload) => ({
    type: SurveyActionTypes.ADD_NEW_QUESTION_SLACK,
    payload,
});

export const addNewAnswerSlack = (payload) => ({
    type: SurveyActionTypes.ADD_NEW_ANSWER_SLACK,
    payload,
});

export const getSingleSurveyWithQandASlack = (payload) => ({
    type: SurveyActionTypes.GET_SINGLE_SURVEY_WITH_QUESTIONS_AND_ANSWERS_SLACK,
    payload,
});

export const addJSONCardtoSurveySlack = (payload) => ({
    type: SurveyActionTypes.ADD_JSON_CARD_TO_SURVEY_SLACK,
    payload,
});

export const getAllSurveysSlack = (payload) => ({
    type: SurveyActionTypes.GET_ALL_SURVEYS_SLACK,
    payload,
});

export const addJSONtoSurveySlack = (payload) => ({
    type: SurveyActionTypes.ADD_JSON_TO_SURVEY_SLACK,
    payload,
});

export const addScheduleSlack = (payload) => ({
    type: SurveyActionTypes.ADD_SCHEDULE_SLACK,
    payload,
});

export const getAllSurveysWithScheduleSlack = (payload) => ({
    type: SurveyActionTypes.GET_ALL_SURVEYS_WITH_SCHEDULE_SLACK,
    payload,
})

export const deleteScheduleSlack = () => ({
    type: SurveyActionTypes.DELETE_SCHEDULE_SLACK,
});