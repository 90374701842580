import interpolate from "color-interpolate";
import {palette} from '../../assets/styles/theme';

export function scoreColor(score: number, maxScore: number): string {
  console.log("SCORE TEST", score, maxScore)
  if (score <= maxScore / 3) {
    return "#FB5523";
  }
  if (score <= 2 * (maxScore / 3)) {
    return "#FDD835";
  }
  if (score <= maxScore) {
    return "#6DAE43";
  }
  return palette.main;
}

export function scoreInterpolationColor(
  score: number,
  maxScore: number,
  interpolationColors: string[] = ["#FB5523", "#FB744B", "#FC9373", "#FDB29B","#FDD835","#FFDE5C","#B1D798","#9BCC7B","#85C05D","#6DAE43"]
): string {
  let colormap = interpolate(interpolationColors);

  /*
  let black = colormap(0); // 'rgb(0, 0, 0)'
  let white = colormap(1); // 'rgb(255, 255, 255)'
  let gray = colormap(0.5); // 'rgb(128, 128, 128)'
  
  const colors = [];
  const colorDiv = 1 / interpolationColors.length;
  for(let i = 0; interpolationColors.length; i++) {
    colors.push(colormap(colorDiv * i));
  }
  */

  return colormap(score / maxScore);
}
