import { Question, Type1Question, Type2Question } from "../../models/Question";
import questionsTemplate from "./questionsTemplate.json";

export class QuestionsConverter {
  protected questions = [] as Question[];
  protected surveyId: string;
  protected numberOfQuestions: number;
  protected numberOfResponses: number;

  protected rawQuestions: any[];
  protected rawQuestionsData: any[];
  protected rawResponses: any[];
  protected pagesLength: number;
  protected pagesLengths = [] as number[];
  protected getQuestionResponses: (question: Question) => any[];
  // protected pagesLengthSum: number;
  // protected pagesLengthSumPrev = 0;

  constructor(
    protected rawDataFull: any,
    protected rawDataWithR: any,
    protected variant: "native" | "teams" | "slack"
  ) {
    this.surveyId = this.rawDataFull.id;
    this.numberOfResponses = this.rawDataFull.numberOfResponses;

    switch (variant) {
      case "native":
        this.numberOfQuestions = this.rawDataFull.native_questions.length;

        this.rawQuestions = rawDataFull.native_questions;
        this.rawResponses = rawDataWithR.native_responses;

        this.rawQuestionsData = this.rawDataFull.json.pages;
        this.pagesLength = rawDataFull.json.pages.length;
        // get the length of each page
        for (const page of rawDataFull.json.pages) {
          this.pagesLengths.push(page.elements.length);
        }

        this.getQuestionResponses = (question: Question) => {
          return this.rawResponses.filter(
            (rawResponse: { nativeQuestionId: number }) =>
              rawResponse.nativeQuestionId === question.id
          );
        };

        break;

      case "teams":
        this.numberOfQuestions = this.rawDataFull.questions.length;

        this.rawQuestions = rawDataFull.questions;
        this.rawResponses = rawDataWithR.responses;

        this.rawQuestionsData = [this.rawDataFull.json];
        this.pagesLength = 1;
        this.pagesLengths = [this.rawDataFull.json.elements.length];

        this.getQuestionResponses = (question: Question) => {
          return this.rawResponses.filter(
            (rawResponse: { questionId: number }) =>
              rawResponse.questionId === question.id
          );
        };

        break;

      case "slack":
        this.numberOfQuestions = this.rawDataFull.slack_questions.length;

        this.rawQuestions = rawDataFull.slack_questions;
        this.rawResponses = rawDataWithR.slack_responses;

        this.rawQuestionsData = [this.rawDataFull.json];
        this.pagesLength = 1;
        this.pagesLengths = [
          this.rawDataFull.json.elements?.length ??
            this.rawDataFull.json.questions.length,
        ];

        this.getQuestionResponses = (question: Question) => {
          return this.rawResponses.filter(
            (rawResponse: { slackQuestionId: number }) =>
              rawResponse.slackQuestionId === question.id
          );
        };

        break;
    }
  }

  public getQuestions(): Question[] {
    return this.questions;
  }

  protected getQuestionsData(): void {
    let question = {} as Question;

    this.rawQuestions.forEach((nativeQuestion, index) => {
      question.id = nativeQuestion.id;
      question.title = nativeQuestion.content;
      question.type =
        nativeQuestion.type.toLowerCase() !== "radio"
          ? nativeQuestion.type.toLowerCase()
          : "radiogroup";
      question.dimension = ""; // ?
      question.score = -1; // this will be obtained in the getResponsesData method
      question.maxScore = -1; // ?
      question.date = nativeQuestion.updatedAt;
      question.surveyId = this.surveyId;

      // get dimension, maxScore and specific question type data
      switch (question.type) {
        case "checkbox":
        case "dropdown":
        case "emotionsratings":
        case "imagepicker":
        // case "matrix":
        case "radiogroup":
        case "rating":
          question = this.getType1QuestionData(question, index);
          break;
        case "comment":
        case "text":
          question = this.getType2QuestionData(question, index);
          break;
        default:
        // do nothing
      }

      this.questions.push(question);
      question = {} as Question;
    });

    console.log("questions (1):", this.questions);
  }

  protected getResponsesData(): void {
    this.questions = this.questions.map((question) => {
      switch (question.type) {
        case "checkbox":
        case "dropdown":
        case "emotionsratings":
        case "imagepicker":
        // case "matrix":
        case "radiogroup":
        case "rating":
          return this.getType1ResponseData(question as Type1Question);
        case "comment":
        case "text":
          return this.getType2ResponseData(question as Type2Question);
        // etc...
        default:
          return question;
      }
    });

    console.log("questions (2):", this.questions);
  }

  protected findAndGetQuestionDataAndDimension(questionIndex: number): {
    questionData: any;
    dimension: string;
  } {
    let questionData: any;
    let pagesLengthSum: number = this.pagesLengths[0];
    let pagesLengthSumPrev = 0;
    let questionPosition = questionIndex;

    let i = 0;
    while (i < this.pagesLength) {
      if (questionPosition >= pagesLengthSum) {
        pagesLengthSumPrev = pagesLengthSum;
        // pagesLengthSum += rawDataFull.json.pages[++i].elements.length;
        pagesLengthSum += this.pagesLengths[++i];
      } else {
        questionPosition = questionPosition - pagesLengthSumPrev;
        questionData =
          // this.rawDataFull.json.pages[i].elements[questionPosition];
          this.rawQuestionsData[i].elements !== undefined
            ? this.rawQuestionsData[i].elements[questionPosition]
            : this.rawQuestionsData[i].questions[questionPosition];
        break;
      }
    }

    let dimension = "";
    let questionsAndDimensions = [] as {
      question: string;
      dimension: string;
    }[];
    if (this.rawQuestionsData[i].name !== undefined) {
      dimension = this.rawQuestionsData[i].name;
    } else {
      for (const templateValue of Object.values(questionsTemplate)) {
        for (const dimensionEntry of Object.entries(templateValue)) {
          for (const question of dimensionEntry[1] as string[]) {
            questionsAndDimensions.push({
              question: question,
              dimension: dimensionEntry[0],
            });
          }
        }
      }
      console.log("questionsAndDimensions:", questionsAndDimensions);
      dimension =
        questionsAndDimensions.find(
          (value) => value.question === questionData.title
        )?.dimension ?? "";
    }

    return {
      questionData: questionData,
      // dimension: this.rawDataFull.json.pages[i].name,
      // dimension: this.rawQuestionsData[i].name ?? "",
      dimension: dimension,
    };
  }

  protected getType1QuestionData(
    question: Question,
    questionIndex: number
  ): Type1Question {
    let resultQuestion = {
      ...question,
      choices: [] as string[],
      choiceValues: [] as number[],
    } as Type1Question; // shallow copy

    let { questionData, dimension } =
      this.findAndGetQuestionDataAndDimension(questionIndex);
    resultQuestion.dimension = dimension;

    const questionType: string = question.type;
    // get choices and max score
    if (questionType !== "rating") {
      /*
      if (questionType === "imagepicker") {
        // resultQuestion = question as RatingQuestion;
        resultQuestion.choices = questionData.choices.map(
          (choice: { value: string }) => choice.value
        );
      } else {
        resultQuestion.choices = questionData.choices;
      }
      */
      if (questionData.choices[0].value !== undefined) {
        if (questionType === "radiogroup" || questionType === "checkbox") {
          resultQuestion.choices = questionData.choices.map(
            (choice: { text: string; value: string }) => choice.text
          );
        } else {
          resultQuestion.choices = questionData.choices.map(
            (choice: { value: string }) => choice.value
          );
        }
      } else {
        resultQuestion.choices = questionData.choices;
      }
      resultQuestion.maxScore = questionData.choices.length;
    } else {
      // resultQuestion = question as RatingQuestion;
      if (questionData.rateValues !== undefined) {
        resultQuestion.choices = questionData.rateValues.map(
          (rateValue: { text: string }) => rateValue.text
        );
        resultQuestion.maxScore = questionData.rateValues.length;
      } else {
        resultQuestion.choices = []; // ["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"];
        resultQuestion.maxScore = 0; // 5;
      }
    }

    return resultQuestion;
  }

  protected getType1ResponseData(question: Type1Question): Type1Question {
    let resultQuestion = { ...question } as Type1Question; // shallow copy

    let questionResponses = this.getQuestionResponses(question);

    let choiceValues = new Array(question.maxScore).fill(0);
    for (const questionResponse of questionResponses) {
      if (question.choices.length > 0) {
        let choiceIndex = question.choices.findIndex(
          (choice) => choice.toString() === questionResponse.content
        );
        choiceValues[choiceIndex]++;
      } else {
        // if max score doesnt exists (= 0), then generate it using only the response values.
        const resizeArrayRight = (
          array: string[],
          length: number,
          fill_with: number
        ) => array.concat(new Array(length).fill(fill_with)).slice(0, length);
        if (+questionResponse.content > choiceValues.length) {
          resultQuestion.choices = resizeArrayRight(
            resultQuestion.choices,
            +questionResponse.content,
            0
          );
          choiceValues = resizeArrayRight(
            choiceValues,
            +questionResponse.content,
            0
          );
        }
        resultQuestion.choices = resultQuestion.choices.map(
          (choice, index) => (choice = (index + 1).toString())
        );
        choiceValues[+questionResponse.content - 1]++;
      }
    }
    resultQuestion.choiceValues = choiceValues;

    // calculate question score
    let responsesNumber = 0;
    let totalValue = 0;
    let average = 0;
    for (let i = 0; i < choiceValues.length; i++) {
      responsesNumber += choiceValues[i];
      totalValue += choiceValues[i] * (i + 1);
    }
    average = totalValue / responsesNumber;
    resultQuestion.score = average;

    return resultQuestion;
  }

  protected getType2QuestionData(
    question: Question,
    questionIndex: number
  ): Type2Question {
    let resultQuestion = {
      ...question,
      answers: [],
      sentimentScores: [],
    } as Type2Question; // shallow copy
    let { dimension } = this.findAndGetQuestionDataAndDimension(questionIndex);
    resultQuestion.dimension = dimension;
    return resultQuestion;
  }

  protected getType2ResponseData(question: Type2Question): Type2Question {
    let resultQuestion = { ...question } as Type2Question; // shallow copy

    let questionResponses = this.getQuestionResponses(question);

    let sentimentScores = [] as number[];
    for (const questionResponse of questionResponses) {
      console.log(questionResponse.content)
      const newContent = questionResponse.content?questionResponse.content.toUpperCase():null
      resultQuestion.answers.push(newContent);
      //resultQuestion.answers.push(questionResponse.content);
      const responseSentiments =
        this.variant !== "slack"
          ? questionResponse.response_sentiments
          : undefined;
      if (responseSentiments !== undefined && responseSentiments.length > 0) {
        /*if (questionResponse.response_sentiments !== undefined) {*/
        sentimentScores.push(+responseSentiments[0].sentimentScore);
      }
    }
    resultQuestion.sentimentScores = sentimentScores;
    if (sentimentScores.length === 0) {
      resultQuestion.score = 0;
    } else {
      // console.log("sentimentValues", sentimentScores);
      resultQuestion.score =
        sentimentScores.reduce((previousValue, currentValue) => {
          console.log("values", previousValue, currentValue);
          return previousValue + currentValue;
        }) / sentimentScores.length;
    }

    return resultQuestion;
  }

  /*
  protected getImagePickerQuestionData(
    question: Question,
    questionIndex: number
  ): void {
    throw new Error("Function not implemented.");
  */

  protected others(): void {}

  public generateQuestions(): void {
    this.getQuestionsData();
    this.getResponsesData();
    this.others();
  }
}
