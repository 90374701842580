import {Navigate, useNavigate, useRoutes} from "react-router-dom";
import HomePage from "./components/home/HomePage";
import SurveySlackIndex from "./components/survey/survey-index/survey-slack-index";
import AssignUsers from "./components/Subscriptions/AssignUsers";
import DisplaySlackSurvey from "./components/DisplaySlackSurvey";
import ResponsesReport from "./components/PBI/ResponsesReport";
import SurveyCreate from "./components/survey/survey-create/survey-create"
import Billing from "./components/billing/billing-index"
import {useTranslation} from "react-i18next";
import { ChartsMain } from "./charts/components/ChartsMain";
import Overview from "./charts/pages/Overview";
import QuestionsData from "./charts/pages/QuestionsData";
import ComparativeInformation from "./charts/pages/ComparativeInformation";

const AppRouting = () => {
    const {t} = useTranslation();
    return useRoutes([
        {
            path: '/',
            element: <HomePage t={t}/>
        },
        /*{
            path: 'dashboard',
            element: <Dashboard/>
        },
        {
            path: 'surveys',
            element: <SurveyIndex/>
        },*/
        {
            path: 'surveys-slack',
            element: <SurveySlackIndex/>
        },
        /*{
            path: 'collect/:id',
            element: <CollectIndex/>
        },
        {
            path: 'takeSurvey/:id',
            element: <TakeSurvey/>
        },*/
        {
            path: 'surveys-show/:id',
            element: <DisplaySlackSurvey/>
        },
        /*
        {
            path: 'r/:id',
            element: <RespondSurvey/>
        },
        {
            path: 'r/sirq/:id',
            element: <RespondSignInRequired/>
        },*/
        {
            path: 'charts/:id',
            element: <ChartsMain/>
        },/*
        {
            path: 'myreport/:id',
            element: <MyReport/>
        },
        {
            path: 'reports',
            element: <ReportIndex/>
        },*/
        {
            path: 'charts/:id',
            element: <ChartsMain/>,
            children: [
                {
                    index: true,
                    element: <Navigate to={"overview"} />
                },
                {
                    path: 'overview',
                    element: <Overview/>
                },
                {
                    path: 'questions-data',
                    element: <QuestionsData/>
                },
                {
                    path: 'comparative-information',
                    element: <ComparativeInformation/>
                }
            ]
        },
        {
            path: 'survey-create/*',
            element: <SurveyCreate/>
        },
        // {
        //     path: 'user-profile',
        //     element: <UserProfile/>
        // },
        {
            path: 'billing',
            element: <Billing/>
        },
        {
            path: 'assignSubscriptions',
            element: <AssignUsers navigate={useNavigate()}/>
        }
    ]);
}

export default AppRouting;