import axios from "axios";

export const getUserSlackService = async (email) => {
    const {data} = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/slack/users/userByEmail`, {email});
    return data;
}

export const getUserSlackLastTimeService = async () => {
    const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/slack/users/userLastTime`);
    return data;
}