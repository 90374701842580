import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {Component} from "react";
import {MsalContext} from "@azure/msal-react";
import axios from "axios";
import {connect} from "react-redux";
import './billing-index.css';
import {format} from "date-fns";
import {getUserSlackThunk} from "../../redux/slack/users/users.thunk";

class Billing extends Component {

    static contextType = MsalContext;


    async componentDidMount() {
        if(!this.props.user[0])
        await this.props.getUserSlack(this.context.accounts[0].idTokenClaims.emails[0]);
    }


    /*this is the first step in the flow, it creates a url with a checkout session and redirects to it
    at this point, if the user purchases the first subscription, he gets a stripeCustomerId assigned in the backend
    */

    purchaseSubscription = async (e) => {
        console.log(e.currentTarget.value);

        let obj = {
            email: this.context.accounts[0].idTokenClaims.emails[0],
            type: e.currentTarget.value
        };
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-checkout-session`, obj);

        window.location.href = res.data;
    }

    /*
    this creates a link to stripe customer portal
    in order to created it, you need to pass stripeCustomerId
    this is the reason we have to first call the above method, so user can get stripeCustomerAssigned
    also, users are not able to purchase subscriptions from the portal (try cancelling all your subs, and then go to the portal again, there would be no option to purchase a plan), 
    so if user cancelled subs and wants to subscribe again, use purchaseSubscription()
    */

    stripePortal = async () => {
        let obj = {
            stripeCustomerId: this.props.selectedUser.stripeCustomerId
        }
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-customer-portal-session`, obj);
        window.location.href = res.data;
    }


    render() {
        console.log('select user', this.props.selectedUser);
        return (
            <div>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <div className="text-center my-5">
                                <Button variant="contained" size="large" onClick={() => this.stripePortal()}>Manage
                                    Billing</Button>
                            </div>
                            {this.props.user.length > 0 && this.props.selectedUser.ownedSubscriptions.length < 1 ?
                            <div className="row">
                                <div className="col-md-6 my-2">
                                    <Card className="card-area-animation text-center">
                                        <CardHeader title="Standard Plan"
                                                    subheader="Purchase First Subscription (Standard)"/>

                                        <CardContent>
                                            <Typography variant="h2">
                                                <b>10 <h4 className="d-inline-block">$<small>/month</small></h4></b>
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="justify-content-center my-3">
                                            <Button value="standard" variant="contained" size="large"
                                                    onClick={(e) => this.purchaseSubscription(e)}>
                                                Buy now
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                                <div className="col-md-6 my-2">
                                    <Card className="card-area-animation text-center">
                                        <CardHeader title="Advanced Plan"
                                                    subheader="Purchase First Subscription (Advanced)"/>

                                        <CardContent>
                                            <Typography variant="h2">
                                                <b>30 <h4 className="d-inline-block">$<small>/month</small></h4></b>
                                            </Typography>
                                        </CardContent>
                                        <CardActions className="justify-content-center my-3">
                                            <Button value="advanced" variant="contained" size="large"
                                                    onClick={(e) => this.purchaseSubscription(e)} color="secondary">
                                                Buy now
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                            </div>
                                : null}
                        </div>
                        <div className="col-md-12 my-4">
                            <h6><b>Owned Subscriptions</b></h6>
                            {this.props.selectedUser && this.props.selectedUser.ownedSubscriptions.length >     0 ?
                                <TableContainer className="w-100">
                                    <Table className="w-100" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Plan</TableCell>
                                                <TableCell align="center">Quantity</TableCell>
                                                <TableCell align="center">Period</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.selectedUser.stripe_subscriptions.map((row, index) => (
                                                row.active ?
                                                    <TableRow key={index}
                                                              sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell align="center">{row.subscriptionJson.plan.amount / 100} {' ' + row.subscriptionJson.plan.currency + ' ' + row.subscriptionJson.plan.interval}</TableCell>
                                                        <TableCell align="center">{row.quantity}</TableCell>

                                                        <TableCell align="center">
                                                            {format(new Date(row.subscriptionJson.current_period_start * 1000), 'MM/dd/yyyy')
                                                            + ' to '
                                                            + format(new Date(row.subscriptionJson.current_period_end * 1000), 'MM/dd/yyyy')}
                                                        </TableCell>
                                                        <TableCell align="center">{row.status}</TableCell>
                                                    </TableRow> : null
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <p>You don't have any valid subscriptions yet</p>}
                        </div>
                    </div>
            </div>
        )
    }


    /*<Button onClick={()=>this.stripePortal()}>Customer Portal</Button>*/
    /* */

    /* now user objects have subscriptions field, it will include only currently active subscriptions (no old, cancelled, etc) */

    /*this.props.user && this.props.user.stripe_subscriptions_natives.length>0 ? <p>*/
    /* this can be used to control what content user has access to based on plan, etc */
    /*{JSON.stringify(this.props.user.stripe_subscriptions_natives)}</p> : <p>You don't have any valid subscriptions yet</p>*/

}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state billing", state);
    return {
        user: state.users.user,
        selectedTeam: state.users.selectedTeam,
        selectedUser: state.users.selectedAccount
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        getUserSlack: (id) => dispatch(getUserSlackThunk(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);