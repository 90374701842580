import * as React from "react";
import SurveyRouting from "./survey-routing";

const SurveyCreate = () => {

    return (
        <div>
            <SurveyRouting/>
        </div>
    );
}

export default SurveyCreate;