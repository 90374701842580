import axios from "axios";
import SlackUserActionTypes from "./users.types";

//ACTION CREATORS

export const changeStatusUser = (payload) => ({
    type: SlackUserActionTypes.CHANGE_STATUS_USER,
    payload,
});

export const getUserSlack = (payload) => ({
    type: SlackUserActionTypes.GET_USER_SLACK,
    payload,
});

export const getUserSlackLastTime = (payload) => ({
    type: SlackUserActionTypes.GET_USER_SLACK_LAST_TIME,
    payload,
});

export const changeSelectedTeam = (payload) => ({
    type: SlackUserActionTypes.CHANGE_TEAM,
    payload
})

export const changeSelectedAccount = (payload) => ({
    type: SlackUserActionTypes.CHANGE_ACCOUNT,
    payload
})
