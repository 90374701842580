import React, {Component} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import "./ResponsesReport.css";
import axios from 'axios';
import {connect} from "react-redux";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

import {Button, CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";
import {getSingleSurveysWithQandASlackThunk} from "../../redux/slack/survey/survey.thunk";
import {MutatingDots} from "react-loader-spinner";
import {palette} from "../../assets/styles/theme";


// let filter = [
//   {
//     $schema: "http://powerbi.com/product/schema#advanced",
//     target: 
//     { table: "public slack_questions", 
        //     column: "slack_survey_id" },
//     filterType: 0, 
//     logicalOperator: "And", 
//     conditions: [{ operator: "Is", value: 34 }]
//   }];


class ResponsesReport extends Component {


    state = {
        id: null,
        config: null,
        style: "report-loading",
        loading: true,
        slicerSet: false
    }

    async componentDidMount() {
        console.log("calling backend");
        let res = await axios.get("https://survey-app-backend.azurewebsites.net/api/pbi/getEmbedInfo");
        // let res = await axios.get("http://localhost:8080/api/pbi/getEmbedInfo");
        console.log(res);
        this.setState({
            config:
                {
                    type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                    id: res.data.embedUrl[0].reportId,
                    embedUrl: res.data.embedUrl[0].embedUrl,
                    accessToken: res.data.accessToken,
                    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        visualSettings: {
                            visualHeaders: [
                                {
                                    settings: {
                                        visible: false
                                    }
                                    /* No selector - Hide visual header for all the visuals in the report */
                                }
                            ]
                        },


                    }
                }
        });
        this.setState({loading: false});
        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]
        await this.props.getSingleSurveySlack(id);
    }


    // componentDidMount(){
    //   this.setState
    // }

    setFilters = async () => {

        console.log('Report loaded');
        let activePage = await window.report.getActivePage();


        console.log(activePage);

        const path = window.location.pathname.split('/')
        const id = path[path.length - 1]

        let filter = await activePage.getFilters();
        filter[0].conditions[0].value = id;
        console.log(filter);

        console.log(window.location);


        try {
            await activePage.updateFilters(models.FiltersOperations.Replace, [filter[0]]);
            console.log("Page filters were replaced.");
        } catch (error) {
            console.log(error);
        }


        const visuals = await activePage.getVisuals();

        console.log("VISUALS");
        console.log(visuals);


        // Retrieve the target visual.
        let slicer = visuals.filter(function (visual) {
            return visual.type === "slicer";
        })[0];

        // Get the slicer state
        let state = await slicer.getSlicerState();

        console.log(state);

        state.filters[0].values = [this.props.singleSlackSurvey.slack_questions[0].content];

        await slicer.setSlicerState({filters: [state.filters[0]]});

        console.log(slicer);
        console.log("Date slicer was set.");


    }

    onRender = () => {
        console.log('Report rendered')
        /*if (this.state.slicerSet) {
        }*/
        this.setState({style: "report-style-class"});
        this.setState({slicerSet: true});
    }


    render() {

        console.log(this.props);
        return (
            <div>
                <AuthenticatedTemplate>
                    <div className="py-3 text-end">
                        <Link to={`/surveys-slack`} style={{textDecoration: 'none'}}>
                            <Button variant="contained">Back</Button>
                        </Link>
                    </div>

                    <div className="d-flex justify-content-center">
                        <MutatingDots
                            color={palette.primary}
                            height="100"
                            width="100"
                            visible={!this.state.slicerSet}/>
                    </div>
                    {!this.state.loading && !this.state.slicerSet ? <p className="text-center">Loading...</p> : null}
                    {this.state.loading? <p className="text-center">Loading data...</p> : null}

                    {this.state.loading ? null :
                        <PowerBIEmbed
                            embedConfig={this.state.config
                            }

                            eventHandlers={new Map([
                                ['loaded', this.setFilters],
                                ['rendered', this.onRender],
                                ['error', function (event) {
                                    console.log(event.detail);
                                }]
                            ])
                            }

                            cssClassName={
                                this.state.style
                            }

                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    window.report = embeddedReport;
                                }
                            }

                        />
                    }

                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please sign-in to view this page</UnauthenticatedTemplate>
            </div>
        )
    }

}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        allSlackSurveys: state.slack.allSlackSurveys,
        singleSlackSurvey: state.slack.singleSlackSurvey,

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        // getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        getSingleSurveySlack: (id) => dispatch(getSingleSurveysWithQandASlackThunk(id))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesReport);
