import {Box, Button, CircularProgress, TablePagination} from "@mui/material";

import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import SurveySlackCardItem from "./survey-card-item";
import SurveyTableSearch from "../survey-table-search";
import {useTranslation} from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, filter) {
    array = array.filter((el) => {
        const include = el.title.toLowerCase().includes(filter.toLowerCase());
        return filter === '' || include;
    });

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const SurveySlackCard = (props) => {
    const {status, surveys, sendSurveyToTeam, sending} = props;
    const [order, setOrder] = useState('desc');
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState('updateAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);

    const {t} = useTranslation();

    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeFilter = (value) => {
        setFilter(value);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - surveys.length) : 0;

    return (
        <Box sx={{width: '100%'}}>
            <div className="py-3">
                <NavLink to='/survey-create' style={{textDecoration: 'none'}}>
                    <Button variant="contained">{t('surveys.newSurvey')}</Button>
                </NavLink>
            </div>
            <div className="my-3">
            <SurveyTableSearch setFilter={handleChangeFilter}/>
            <div className="row">
                {status === 'loading' ?
                    <div className="text-center col-12">
                        <CircularProgress/>
                    </div>
                    :
                    stableSort(surveys, getComparator(order, orderBy), filter)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) =>
                            <div className="col-md-6 my-2" key={index}>
                                <SurveySlackCardItem key={index} item={item} sendSurveyToTeam={sendSurveyToTeam} sending={sending}/>
                            </div>
                        )}
            </div>
            <TablePagination
                className="child-mb-0"
                rowsPerPageOptions={[6, 10, 24]}
                component="div"
                count={surveys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
            </div>
            {/*<div className="py-3">
                <NavLink to='/survey-create' style={{textDecoration: 'none'}}>
                    <Button variant="contained">+ New Survey</Button>
                </NavLink>
            </div>*/}
        </Box>
    );
}

export default SurveySlackCard;