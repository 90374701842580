import React, {Component} from "react";
import {Chip, Stack, Typography} from "@mui/material";
import {EventRounded} from "@mui/icons-material";


class DisplaySchedule extends Component {

    // componentDidMount (){
    //     this.getDaysOfTheWeek(this.props.schedule-slack);
    // }

    getDaysOfTheWeek = (schedule) => {

        let days = {
            0: "Sun",
            1: "Mon",
            2: "Tue",
            3: "Wed",
            4: "Thur",
            5: "Fri",
            6: "Sat"
        }

        let daysString = [];

        for (let i = 0; i < schedule.days.length; i++) {

            console.log("loop");
            if (schedule.days[i] === true) {

                daysString.push(days[i]);
            }
        }

        return daysString;

    }

    constructTime = (hours, minutes) => {


        let prefix;
        if (hours >= 12) {
            prefix = "p.m"
        } else {
            prefix = "a.m"
        }
        let myHours = ((hours + 11) % 12 + 1);

        let myMinutes = minutes;
        if (minutes < 10) {

            myMinutes = "0" + myMinutes;

        }

        return myHours + ":" + myMinutes + " " + prefix;

    }


    render() {

        let days = this.getDaysOfTheWeek(this.props.schedule);
        let time = this.constructTime(this.props.schedule.hours, this.props.schedule.minutes);

        return (
            <div>
                <div className="text-black-50 d-flex mb-2">
                    {'Scheduled: '}
                    <Stack direction="row" className="flex-wrap">
                        {days.map((day, index) => <Chip label={day} key={index} icon={<EventRounded/>} color="info" size="small" className="mb-1 ms-1"/>)}
                    </Stack>
                </div>
                <Typography className="text-info ms-2"><b>at {time} ({this.props.schedule.timeZone || 'GMT'})</b></Typography>
            </div>
        )
    }
}

export default DisplaySchedule;