import employee from "../../assets/employee.gif";
import './home.css';
import {Alert, Button, capitalize, CircularProgress, Collapse, IconButton, Snackbar} from "@mui/material";
import {AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate} from "@azure/msal-react";
import {SignInButton} from "../../auth/ui";
import React, {Component} from "react";
import SurveySlackIndex from "../survey/survey-index/survey-slack-index";
import {connect} from "react-redux";
import {getUserSlackLastTimeThunk, getUserSlackThunk} from "../../redux/slack/users/users.thunk";
import {Close} from "@mui/icons-material";

class HomePage extends Component {

    static contextType = MsalContext;
    state = {
        openLastUser: true
    }

    setOpenLast(value) {
        this.setState({openLastUser: value})
    }

    render() {
        const {t} = this.props;
        return (
            <div>

                <div>
                    <Collapse in={this.props.lastUser && this.state.openLastUser}>
                        <Alert action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    this.setOpenLast(false);
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                               severity="success" sx={{width: '100%', mb: 2}}>
                            Success!, Moodbit Pulse was successfully installed in your workspace
                        </Alert>
                    </Collapse>
                </div>

                <AuthenticatedTemplate>
                        <div>
                            {this.props.user.length > 0 && this.props.user[0].stripe_subscriptions.length > 0 ?
                                <div>
                                    {/*this.context.accounts.length > 0 ?
                                        <div>
                                            <Chip
                                                color="success"
                                                label={`Your team id: ` + this.context.accounts[0].idTokenClaims.extension_teamId}
                                                icon={<Key/>}
                                                variant="outlined"
                                            />

                                            {this.props.user[0].admin ?
                                                <div className="text-center">
                                                    <CustomerPortal email={this.context.accounts[0].username}/>
                                                    <Link to={{
                                                        pathname: "/assignSubscriptions",
                                                        state: {email: this.context.accounts[0].username}
                                                    }} className="text-decoration-none"><Button variant="contained">Assign
                                                        Subscriptions</Button></Link>

                                                </div> : <p/>}
                                        </div>
                                        : <p/>*/}
                                    <SurveySlackIndex/>
                                </div> :
                                <div>
                                    {this.context.accounts.length > 0 && this.props.user.length > 0 && this.props.user[0].admin ?
                                        {/*<div>
                                            <Chip
                                                color="success"
                                                label={`Your team id: ` + this.context.accounts[0].idTokenClaims.extension_teamId}
                                                icon={<Key/>}
                                                variant="outlined"
                                            />
                                            <div className="text-center">
                                                <CustomerPortal email={this.context.accounts[0].username}/>
                                                <Link to={{
                                                    pathname: "/assignSubscriptions",
                                                    state: {email: this.context.accounts[0].username}
                                                }} className="text-decoration-none">
                                                    <Button variant="contained">Assign Subscriptions</Button>
                                                </Link>
                                            </div>
                                        </div>*/
                                        }
                                        :
                                        <p>You don't have any subscriptions to access the dashboard yet. Ask your admin
                                            to
                                            purchase one and assign it to you</p>}
                                </div>
                            }
                        </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {this.context.inProgress !== 'none' ?
                        this.context.inProgress === 'login' || this.context.inProgress === 'logout' ?
                            <div className="text-center">
                                <p>{`${capitalize(this.context.inProgress)} in progress...`}</p>
                                <CircularProgress/>
                            </div> : null :
                        <div className="row h-100 mt-5">
                            <div className="col-md-6">
                                <p className="fs-lg-1">
                                    <b>{t("home.title")}</b>
                                </p>
                                <p className="fs-5">{t('home.description')}</p>
                                {/*<p className="fs-lg-1">
                                    <b>Bring out the best in your <span className="underline">team.</span></b>
                                </p>
                                <p className="fs-5">A single platform to engage with your team members and
                                    cultivate trust. Tackle challenges and build strengths so your team can do its best
                                    work.</p>*/}
                                {/*<Button variant="contained" color="primary" size="large"
                                        className="mt-2">Get Started</Button>*/}

                                <SignInButton variant="outlined" color="primary" size="large" className="mt-2"/>
                            </div>
                            <div className="col-md-6 text-center">
                                <img width="100%" src={employee} alt=""/>
                            </div>
                        </div>}
                </UnauthenticatedTemplate>
            </div>
        );
    }

}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        user: state.users.user,
        lastUser: state.users.lastUser,
        selectedUser: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSlack: (email) => dispatch(getUserSlackThunk(email)),
        getUserSlackLastTime: () => dispatch(getUserSlackLastTimeThunk())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
